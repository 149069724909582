import * as React from "react"
import Layout from "../components/layout"
const IndexPage = () => (
  <Layout>
    <div class="overflow-x-hidden bg-gradient-to-b from-yellow-500">
      <div class="px-6 py-8 ">
        <div class="container flex justify-between mx-auto">
          <div class="container overflow-x-hidden ">
            <div class="px-0 py-8">
              <div class="bg-white lg:mx-10 lg:flex  lg:shadow-lg lg:rounded-lg">
                <div class="lg:w-1/2 items-center">
                  <div
                    class="h-64 bg-cover  lg:h-full"
                    style={{
                      backgroundImage: "url('/praise_and_worship_1.jpg')",
                    }}
                  />
                </div>
                <div class="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                  <h2 class="text-3xl text-red-800 font-bold">
                    Praise and Worship
                  </h2>
                  <p class="mt-4 text-gray-600">
                    Vincent Juma has been in the praise and worship ministry for
                    close to a decade at Calvary chapel Nairobi, serving God in
                    various ministerial roles such as leading worship, teaching
                    and preaching, disciple-ship and training other worship
                    leaders.
                  </p>
                  <div class="mt-8">
                    <a
                      href="#"
                      class="bg-red-900 text-gray-100 px-5 py-3 font-semibold rounded"
                    >
                      Contact Juma
                    </a>
                  </div>
                </div>
              </div>

              <div class="bg-white lg:mx-10 lg:flex  lg:shadow-lg lg:rounded-lg mt-10">
                <div class="lg:w-1/2 items-center">
                  <div
                    class="h-64 bg-cover  lg:h-full"
                    style={{
                      backgroundImage: "url('/praise_and_worship.jpg')",
                    }}
                  />
                </div>
                <div class="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                  <h2 class="text-3xl text-red-800 font-bold">Helps</h2>
                  <p class="mt-4 text-black">
                    Ibrahim Olele has been serving the lord at calvary chapel
                    since 2008 when he received the Lord and was baptised with
                    the holy spirit. He is a constant worshipper and we can
                    always count on him to be at any church service helping in
                    every way possible. Ibrahim is also very active in actively
                    sharing the gospel of Jesus Christ.
                  </p>
                  <div class="mt-8">
                    <a
                      href="#"
                      class="bg-red-900 text-gray-100 px-5 py-3 font-semibold rounded"
                    >
                      Contact
                    </a>
                  </div>
                </div>
              </div>

              <div class="bg-white lg:mx-10 lg:flex  lg:shadow-lg  mt-10">
                <div class="lg:w-1/2  items-center">
                  <div
                    class="h-64 bg-cover  lg:h-full"
                    style={{ backgroundImage: "url('/bible.jpg')" }}
                  />
                </div>
                <div class="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                  <h2 class="text-3xl text-red-800 font-bold">Teens</h2>
                  <p class="mt-4 text-black">
                    {" "}
                    Christopher has been serving God through the youth and teens
                    ministry. Through his work he is blessed to see young
                    believers grow in their relationship with Christ
                  </p>
                  <div class="mt-8">
                    <a
                      href="#"
                      class="bg-red-900 text-gray-100 px-5 py-3 font-semibold rounded"
                    >
                      Contact Christopher
                    </a>
                  </div>
                </div>
              </div>

              <div class="bg-white lg:mx-10 lg:flex  lg:shadow-lg lg:rounded-lg mt-10">
                <div class="py-12 px-6 max-w-xl lg:max-w-5xl lg:w-1/2">
                  <h2 class="text-3xl text-gray-800 font-bold">
                    Women ministry
                  </h2>
                  <p class="mt-4 text-gray-600">Coming soon!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
